import React, { useEffect, useState } from 'react';
import './home.css';
import video from '../../Assets/video.mp4';
import { GrLocation } from 'react-icons/gr';
import { FaSearch } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { BsListTask } from 'react-icons/bs';
import { TbApps } from 'react-icons/tb';
import { data } from '../../data.js'

import Aos from 'aos';
import 'aos/dist/aos.css';

const Home = ({ onSearch }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [cityName, setCityName] = useState('');
  const [maxPrice, setMaxPrice] = useState(450); // State for managing maximum price
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = async () => {
    setLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 2000));

    onSearch({ cityName: cityName.toLowerCase(), maxPrice });
    setLoading(false);
  };

  const handlePriceChange = (e) => {
    setMaxPrice(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    let filteredResults = data;

    // Filter based on city name
    if (cityName) {
      filteredResults = filteredResults.filter((item) =>
        Array.isArray(item.location)
          ? item.location.some((loc) => loc.toLowerCase() === cityName)
          : item.location.toLowerCase() === cityName
      );
    }

    // Filter based on max price
    filteredResults = filteredResults.filter(
      ({ fees }) => parseFloat(fees) <= parseFloat(maxPrice)
    );

    setFilteredData(filteredResults); // Ensure this sets the filteredData state correctly
  }, [cityName, maxPrice]);
  

  return (
    <section id='home' className="home">
      <div className="overlay"></div>
      <div className="img"></div>
      <div className="homeContent container">
        <div className="textDiv">
          <span data-aos="fade-up" className="smallText">
            Our Packages
          </span>

          <h1 data-aos="fade-up" className="homeTitle">
            Search your Room
          </h1>
        </div>

        <div data-aos="fade-up" className="cardDiv grid">
          <div className="destinationInput">
            <label htmlFor="city">Search your location:</label>
            <div className="input flex">
              <input
                type="text"
                placeholder="Enter city name here...."
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
              />
              <GrLocation className="icon" />
            </div>
          </div>
          <div className="dateInput">
            <label htmlFor="date">Select your date:</label>
            <div className="input flex">
              <input type="date" id='date' placeholder="Enter name here...." />
            </div>
          </div>
          <div className="priceInput">
            <div className="label_total flex">
              <label htmlFor="price">Max price:</label>
              <h3 className="total">£{maxPrice}</h3> {/* Display the max price */}
            </div>
            <div className="input flex">
              <input
                type="range"
                max="900"
                min="380"
                value={maxPrice}
                onChange={handlePriceChange}
              />
            </div>
          </div>

          <div className="searchOptions flex">
            <FaSearch className="icon" />
            <button onClick={handleSearch}>{loading ? 'Searching...' : 'Search'}</button>
          </div>
        </div>

        <div data-aos="fade-up" className="homeFooterIcons flex">
          <div className="rightIcons">
            <FiMail className="icon" />
            <TfiHeadphoneAlt className="icon" />
          </div>
          <div className="leftIcons">
            <BsListTask className="icon" />
            <TbApps className="icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
